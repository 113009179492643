#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	z-index: 2147482998;
	transition: opacity 0.01s;
	opacity: 1;

	img {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 64px;
	}
}

body :not(.preloader) {
	opacity: 0;
}
body.loaded :not(.preloader) {
	transition: opacity 0.01s;
	opacity: 1;
}
